(function($){
  
  /////////////　MVスライダー /////////////
  const mv = new Swiper('.mv-swiper', {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
      waitForTransition: false, 
    },
    pagination: {
      el: '.mv-swiper-pagination',
      clickable:true,
    }
  });

  if (window.matchMedia('(min-width: 768px)').matches) {
    // スライダー
    var topics_slider = {};

    topics_slider = {
      speed: 300,
      autoplay: { 
        delay: 3000, 
      },
      slidesPerView: 4.5,
      spaceBetween: 24,
      navigation: {
        nextEl: '.topics-button-next',
        prevEl: '.topics-button-prev',
      }
    }

    $(document).ajaxStop(function() {
      new Swiper('#topics-swiper', topics_slider);
    })
    
  }
})( jQuery );